import { computed, ref, watch, toRaw, onMounted } from '#imports';
import axios from 'axios';
import { merge, debounce as _debounce } from 'lodash-es';

import { getCurrentInstance } from '../utils/getCurrentInstance';

const DEBOUNCE_DEFAULT_SETTINGS = {
  leading: false,
  trailing: true,
};

export const CSS_CLASSES_DEFAULT = {
  root: 'dadata',
  list: 'dadata__list',
  input: 'dadata__input',
  listEmpty: 'dadata__list--empty',
  row: 'dadata__list-row',
};

export const propsComponent = {
  modelValue: {
    type: String,
    required: true,
  },
  token: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'address',
  },
  setInputValue: {
    type: Function,
  },
  apiUrl: {
    type: String,
    default: null,
  },
  inputId: {
    type: String,
    default: null,
  },
  inputName: {
    type: String,
    default: 'address',
  },
  placeholder: {
    type: String,
    default: null,
  },
  mergeParams: {
    type: Object,
    default: () => ({}),
  },
  debounceWait: {
    type: Number,
    default: 150,
  },
  debounceOptions: {
    type: Object,
    default: () => ({}),
  },
  cssClasses: {
    type: Object,
    default: () => ({}),
  },
  locations: {
    type: Object,
    default: () => ({}),
  },
  fromBound: {
    type: String,
  },
  toBound: {
    type: String,
  },
  limit: {
    type: Number,
    default: 5,
  },
};

export const emitsComponent = [
  'update:modelValue',
  'onSelected',
  'focus',
  'input',
];

export const useDaData = () => {
  const { props, emit, pluginSettings } = getCurrentInstance();
  const suggestions = ref([]);
  const showList = ref(false);
  const dadataDom = ref(null);
  const requestCache = new Map();

  const token = computed(() => {
    if (props.token) return props.token;
    if (pluginSettings?.token) return pluginSettings.token;
    return null;
  });
  const localValue = computed({
    get() {
      return props.modelValue;
    },
    set(val) {
      emit('update:modelValue', val);
    },
  });
  const computedCssClasses = computed(() => {
    return { ...CSS_CLASSES_DEFAULT, ...props.cssClasses };
  });
  const url = computed(() => {
    if (props.apiUrl) {
      return props.apiUrl;
    }

    return `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${props.type}`;
  });

  function makeRequestParams(url, token, query, data = {}, params = {}) {
    if (!token || !query.trim()) {
      if (!token) {
        console.error('useDaData.js: Токен не найден');
      }
      if (!query.trim()) {
        console.error('useDaData.js: Пустой запрос');
      }
      return {};
    }

    return merge(
      {
        method: 'POST',
        url: url,
        headers: {
          Authorization: `Token ${token}`,
          'content-type': 'application/json',
          accept: 'application/json',
        },
        data: buildQueryData(data),
      },
      params,
    );
  }

  function buildQueryData(extra = {}) {
    const data = {
      query: localValue.value,
      ...props.locations,
    };

    if (props.fromBound) {
      data['from_bound'] = {
        value: props.fromBound,
      };
    }

    if (props.toBound) {
      data['to_bound'] = {
        value: props.toBound,
      };
    }

    return merge(data, extra);
  }

  const bindCloseClickOutside = () => {
    document.addEventListener('click', (event) => {
      if (dadataDom.value && !dadataDom.value.contains(event.target)) {
        showList.value = false;
      }
    });
  };

  const prepareResults = (data, key) => {
    let copyValue = data.value;

    if (!key) {
      console.error('useDaData.js: Укажите ключ в объекте');
      return '';
    }

    if (typeof key === 'object') {
      const keyParent = Object.keys(key)[0];

      if (!data[keyParent]) {
        console.error(`useDaData.js: ${keyParent} не найден в объекте dadata`);
        return '';
      }

      const keyChild = key[keyParent];

      if (!data[keyParent][keyChild]) {
        console.error(`useDaData.js: ${keyChild} не найден в объекте dadata`);
        return '';
      }

      copyValue = data[keyParent][keyChild];
    } else if (typeof key === 'string') {
      if (!data[key]) {
        console.error(`useDaData.js: ${key} не найден в объекте dadata`);
        return '';
      }

      copyValue = data[key];
    }
    const splitValue = localValue.value?.split(' ') || [];

    if (splitValue.length > 0) {
      splitValue.forEach((word) => {
        copyValue = copyValue.replace(
          word,
          `<span class="highlights">${word}</span>`,
        );
      });
    }

    return copyValue;
  };

  function apiRequest(query, data = {}) {
    const params = makeRequestParams(
      url.value,
      token.value,
      query,
      data,
      props.mergeParams,
    );

    console.log('params', params);

    return axios(params).then((response) => {
      if (response && response.data) {
        if (typeof response.data.suggestions !== 'undefined') {
          return response.data.suggestions;
        } else {
          console.error('useDaData.js: Свойство suggestions не найдено');
        }
      }
    });
  }

  function restoreSuggestion() {
    const query = localValue.value?.trim();

    if (!query) {
      return;
    }

    if (requestCache.has(query)) {
      setSelected(requestCache.get(query));

      return;
    }

    apiRequest(query, { count: 1 }).then((suggestions) => {
      if (suggestions && suggestions[0]) {
        setSelected(suggestions[0]);
      }
    });
  }

  const search = _debounce(
    () => {
      const query = localValue.value?.trim();

      if (requestCache.has(query)) {
        suggestions.value = requestCache.get(query);

        return;
      }

      apiRequest(localValue.value).then((response) => {
        if (response) {
          requestCache.set(query, response?.slice?.(0, props.limit));
          suggestions.value = response?.slice?.(0, props.limit);
        }
      });
    },
    props.debounceWait,
    { ...DEBOUNCE_DEFAULT_SETTINGS, ...props.debounceOptions },
  );

  function setSelected(data) {
    localValue.value = data.value;

    if ('setInputValue' in props && typeof props.setInputValue === 'function') {
      localValue.value = props.setInputValue(toRaw(data));
    }

    emit('onSelected', data);
  }

  const onSelected = (data) => {
    setSelected(data);

    showList.value = false;
  };

  const onFocus = (event) => {
    showList.value = true;

    if (localValue.value) {
      search();
    }
    emit('focus', event);
  };

  const onInput = (event) => {
    showList.value = true;
    const val = event.target.value;

    if (val) {
      search();
    } else {
      suggestions.value = [];
    }

    emit('input', event);
  };

  onMounted(() => {
    bindCloseClickOutside();
  });

  watch(
    () => props.modelValue,
    () => {
      localValue.value = props.modelValue;
    },
  );

  return {
    restoreSuggestion,
    setSelected,
    search,
    onInput,
    onFocus,
    showList,
    localValue,
    prepareResults,
    computedCssClasses,
    onSelected,
    dadataDom,
    suggestions,
  };
};

export const getRegionById = async (id, token = '') => {
  if (!token) return;

  const url =
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address';

  try {
    const response = await axios.post(
      url,
      { query: id },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};
