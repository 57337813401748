<template>
  <div ref="dadataDom" :class="computedCssClasses.root">
    <slot name="input" :on-input="onInput" :on-focus="onFocus">
      <input
        :id="inputId"
        v-model="localValue"
        type="text"
        :name="inputName"
        :class="computedCssClasses.input"
        :placeholder="placeholder"
        @input="onInput"
        @focus="onFocus"
      />
    </slot>

    <Transition name="fade">
      <ul
        v-if="showList"
        :class="[
          computedCssClasses.list,
          { [`${computedCssClasses.listEmpty}`]: suggestions.length === 0 },
        ]"
      >
        <li
          v-for="(item, index) of suggestions"
          :key="index"
          :class="computedCssClasses.row"
          @click="onSelected(item)"
        >
          <slot name="list-item" :prop="item" :prepare-value="prepareResults">
            <span v-html="prepareResults(item, 'value')" />
          </slot>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script setup>
import {
  useDaData,
  emitsComponent,
  propsComponent,
} from './composables/useDaData';

defineProps(propsComponent);
defineEmits(emitsComponent);

const {
  restoreSuggestion,
  prepareResults,
  onSelected,
  onFocus,
  onInput,
  showList,
  localValue,
  suggestions,
  computedCssClasses,
  dadataDom,
} = useDaData();

defineExpose({
  restoreSuggestion,
});
</script>

<style lang="scss" scoped>
.dadata {
  $parent: &;
  $color-basis: $color-black;
  $color-bg: $color-white;
  $color-hover: $color-black;
  $color-bg-active-item-list: $color-blue-2;

  position: relative;

  &__list {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 3;
    padding: em(16) em(12);
    margin-top: em(-20);
    background-color: $color-bg;
    border: 1px solid rgb(0 0 0 / 35%);
    border-radius: em(24);

    &--empty {
      display: none;
    }
  }

  &__list-row {
    @include text-overflow; // что бы переносился текст убрать

    padding: em(12, 16);
    font-size: em(16);
    color: $color-basis;
    cursor: pointer;
    border-radius: em(16);
    transition: background-color 0.3s;

    @include hover {
      background-color: $color-bg-active-item-list;
    }
  }
}
</style>
