import { getCurrentInstance as originGetCurrentInstance } from '#imports';

export const getCurrentInstance = () => {
  const instance = originGetCurrentInstance();

  if (instance) {
    return {
      props: instance.props,
      emit: instance.emit,
      pluginSettings:
        instance.appContext.config.globalProperties.$daDataNext ?? '',
    };
  }

  return {
    props: {
      apiUrl: '',
      cssClasses: {},
      inputName: '',
      modelValue: '',
      placeholder: '',
      token: '',
      type: '',
      mergeParams: {},
      setInputValue: undefined,
      debounceWait: 0,
      debounceOptions: {},
    },
    emit: () => null,
    pluginSettings: undefined,
  };
};
